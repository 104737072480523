import React, {useRef, useState} from 'react';
import { graphql } from 'gatsby';
import styled from 'styled-components';
import ReCAPTCHA from "react-google-recaptcha";

const Styles = styled.div`
	.container {
        display: inline-block;
	}
	
	.contact-page {
		margin-top: 3.125rem;
		padding: 0 1.25rem;
	}

	.methods {
		margin-top: 3.125rem;
		
	}

	a {
		color: var(--black);
	}
	
	h1 {
		margin-bottom: 1rem;
	}
	
	h2 {
		margin-bottom: 1rem;
	}

	.ico {
		height: 1.25rem;
		width: 1.25rem;
		margin: 0 1rem 0 0;
	}

	.address p {
		margin: 0 0 0 2rem;
	}

	.box {
		box-sizing: border-box;
		background: white;
		border: 0.063rem solid rgba(0,0,0,0.1);
		border-radius: 0.25rem;
		padding: 1rem;
		margin-bottom: 1rem;
		display: flex;
	}

	.methods-copy {
		margin-bottom: 1rem;
	}

	.form {
		form {
			box-sizing: border-box;
			background: white;
			border: 0.063rem solid rgba(0,0,0,0.1);
			border-radius: 0.25rem;
			padding: 1rem;
			margin-bottom: 1rem;
		}

		label {
			padding: 0.25rem 0;
			display: block;
			font-size: 0.875rem;
			color: var(--black);
			margin-bottom: 0.625rem;
			font-weight: 700;
		}

		input,
		textarea {
			background-color: #FFF;
			border: solid 0.0625rem #979797;
			height: 3.125rem;
			border-radius: .188rem;
			width: 100%;
			font-size: .875rem;
			outline: 0;
			padding: 0 1rem;
			box-sizing: border-box;
			box-shadow: none;
			margin: 0 0 1rem;

			&:focus {
				border: solid 0.0625rem var(--primary);
			}
		}

		textarea {
			height: 5.625rem;
			resize: vertical;
			padding: .25rem 1rem;
		}

		button {
			border: none;
			background-color: var(--secondary);
			position: relative;
			height: 3.125rem;
			z-index: 1;
			border-radius: .313em;
			line-height: 3.125rem;
			font-size: 0.875rem;
			font-weight: 700;
			color: var(--white);
			text-align: center;
			outline: 0;
			box-sizing: border-box;
			display: inline;
			width: calc(50% - 0.3125rem);
			margin: 1.25rem 0 0 0;
			cursor: pointer;
			   
			&:hover, &:focus {
				background: var(--lightenButton)
			}
		}
	}

	@media only screen and (min-width: 1024px) {
		.contact-page {
			padding:0;
		}        
		
		.container {
            display: inline-block;
            margin-left: 50%;
            transform: translate(-50%);
		}

		.flex-container {
			display: flex;
		}

		.methods {
			margin-right: 3.75rem;
		}

		.form {
			    margin-top: 3.125rem;
		}

		.form, 
		.methods {
			width: 50%;
		}
	}
`;

const SuccessMessage = styled.div`
  font-weight: 600;
  line-height: 1.4rem;
`;

const ErrorMessage = styled.div`
  color: #a02e2e;
  font-size: 0.75rem;
  margin-top: 0.5rem;
`;

const contactPage = () => {
	const [state, setState] = useState({});
	const [isError, setError] = useState(false);
	const [captchaValue, setCaptchaValue] = useState(null);
	const [submissionStatusSuccessful, setSubmissionStatusSuccessful] = useState(false);
	const recaptchaRef = useRef(null);

	const captchaKey = process.env.SITE_RECAPTCHA_KEY || "6Leu0iAqAAAAAGQJapobqbTUAwTs5bIJoagXN8bV";

	const handleChange = e => {
		setState({ ...state, [e.target.name]: e.target.value });
	};

	const handleCaptchaChange = (value) => {
		setCaptchaValue(value);
	};

	const handleSubmit = (event) => {
		event.preventDefault();

		const myForm = event.target;
		const formData = new FormData(myForm);

		fetch("/", {
			method: "POST",
			headers: {"Content-Type": "application/x-www-form-urlencoded"},
			body: new URLSearchParams(formData).toString(),
		})
			.then(() => {
				setSubmissionStatusSuccessful(true);
			})
			.catch((error) => {
				setError(true);
			});
	};

	return (
		<Styles>
			<div className="pad">
				<section className="contact-page static">
					<div className="container">
					<div className="col-1 hidden-m"/>
						<div className="col-10">
							<h1>Contact Romantic Cottages</h1>
							<p>
								Do you have a question or need some help with your booking? Feel free to contact our
								friendly customer service team using one of the methods below. If your question is more
								general, have a look through the <a href="https://www.snaptrip.com/faq" target="_blank">
								Snaptrip Group FAQs</a> as you may find exactly what you're looking for!
							</p>
							<div className="flex-container">
								<div className="methods">
									<div className="box phone">
										<svg className="ico" viewBox="0 0 512 512">
											<path d="m502 445c-9 12-33 32-47 41-2 1-4 2-5 2-14-6-38-18-45-27-3-4-9-5-14-1-4 3-5 9-1 14 6 7 18 15 29 21-9 2-21 4-36 4l0 0c-46 0-138-16-244-119-152-147-123-278-119-292 0-2 0-3 0-4-1-6 0-11 3-16 8-15 28-40 39-49 5-5 10-7 13-7 2 0 6 0 14 10l84 115c6 7 4 17-2 22l-42 32c0 0 0 0-1 0-11-8-26-21-30-28-3-5-10-6-14-3-5 3-6 9-3 13 6 10 22 23 32 31-8 33 17 76 81 139 50 51 87 61 109 61 10 0 17-2 20-4 3-1 5-4 5-7 1-1 1-3 1-5 0-2 0-4 0-5l30-42c4-6 14-8 21-3l118 80c7 5 10 9 11 13 0 3-2 8-7 14z m-252-338c-5-2-8-7-6-13 1-5 7-8 12-6 84 25 149 90 175 173 1 5-2 11-7 13-1 0-2 0-3 0-4 0-8-3-9-7-24-77-85-137-162-160z m14-74c-5-1-9-7-7-12 1-5 6-9 12-7 123 29 217 128 240 252 1 6-3 11-8 12-1 0-2 0-2 0-5 0-9-3-10-8-21-117-109-210-225-237z"/>
										</svg>
										<div>
											<a href="tel:02036370812">
												0203 637 0812
											</a>
											<br/>
											<span>Mon-Fri: 8:30am to 9:30pm</span>
											<br/>
											<span>Sat-Sun: 9:30am to 9:30pm</span>
										</div>
									</div>
									<div className="box email">
										<svg className="ico" viewBox="0 0 512 512">
											<path d="m506 56l-500 0 0 46 250 204 250-204z m-23 31l-227 188-227-188 0-8 454 0z m23 332l-148-159-20 19 109 116-385 0 112-116-20-19-148 159z m0-36l0-249-23 16 0 212z m-500-3l23-23 0-208-23-16z"/>
										</svg>
										<a href="mailto:support@snaptrip.com">support@snaptrip.com</a>
									</div>
									<div className="box address">
										<p>
											Snaptrip,
											<br/>
											Office LG.03,
											<br/>
											Cargo Works,
											<br/>
											1 - 2 Hatfields,
											<br/>
											London,
											<br/>
											SE1 9PG
										</p>
									</div>
									<p className="methods-copy">For all marketing enquiries, please contact:</p>
									<div className="box marketing-email">
										<svg className="ico" viewBox="0 0 512 512">
											<path d="m506 56l-500 0 0 46 250 204 250-204z m-23 31l-227 188-227-188 0-8 454 0z m23 332l-148-159-20 19 109 116-385 0 112-116-20-19-148 159z m0-36l0-249-23 16 0 212z m-500-3l23-23 0-208-23-16z"/>
										</svg>
										<a href="mailto:marketing@snaptrip.com">marketing@snaptrip.com</a>
									</div>
									<div className="box marketing-phone">
										<svg className="ico" viewBox="0 0 512 512">
											<path d="m502 445c-9 12-33 32-47 41-2 1-4 2-5 2-14-6-38-18-45-27-3-4-9-5-14-1-4 3-5 9-1 14 6 7 18 15 29 21-9 2-21 4-36 4l0 0c-46 0-138-16-244-119-152-147-123-278-119-292 0-2 0-3 0-4-1-6 0-11 3-16 8-15 28-40 39-49 5-5 10-7 13-7 2 0 6 0 14 10l84 115c6 7 4 17-2 22l-42 32c0 0 0 0-1 0-11-8-26-21-30-28-3-5-10-6-14-3-5 3-6 9-3 13 6 10 22 23 32 31-8 33 17 76 81 139 50 51 87 61 109 61 10 0 17-2 20-4 3-1 5-4 5-7 1-1 1-3 1-5 0-2 0-4 0-5l30-42c4-6 14-8 21-3l118 80c7 5 10 9 11 13 0 3-2 8-7 14z m-252-338c-5-2-8-7-6-13 1-5 7-8 12-6 84 25 149 90 175 173 1 5-2 11-7 13-1 0-2 0-3 0-4 0-8-3-9-7-24-77-85-137-162-160z m14-74c-5-1-9-7-7-12 1-5 6-9 12-7 123 29 217 128 240 252 1 6-3 11-8 12-1 0-2 0-2 0-5 0-9-3-10-8-21-117-109-210-225-237z"/>
										</svg>
										<a href="tel:02036370812">0203 637 0812</a>
									</div>
									<p className="methods-copy">For all press enquiries, please contact:</p>
									<div className="box press">
										<svg className="ico" viewBox="0 0 512 512">
											<path d="m506 56l-500 0 0 46 250 204 250-204z m-23 31l-227 188-227-188 0-8 454 0z m23 332l-148-159-20 19 109 116-385 0 112-116-20-19-148 159z m0-36l0-249-23 16 0 212z m-500-3l23-23 0-208-23-16z"/>
										</svg>
										<a href="mailto:pr@snaptrip.com">pr@snaptrip.com</a>
									</div>
								</div>
								<div className="form">
									{!submissionStatusSuccessful ?
                    <form data-netlify="true" name="contact" method="post" onSubmit={handleSubmit} data-netlify-recaptcha="true" data-netlify-honeypot="bot-field">
											<div className="form-box">
												<label htmlFor="name">Name:</label>
												<input placeholder="Name" type="text" name="name" id="name" onChange={handleChange} required/>
											</div>
											<div className="form-box">
												<label htmlFor="email">Email:</label>
												<input placeholder="Email Address" type="email" name="email" id="email" onChange={handleChange} required/>
											</div>
											<div className="form-box">
												<label htmlFor="message">Message:</label>
												<textarea className="hidden" name="message" id="message" onChange={handleChange} required/>
											</div>
											<input type="hidden" name="form-name" value="contact"/>
											{isError && <ErrorMessage>There was an error submitting the form. Please try again.</ErrorMessage>}
											<div>
												<ReCAPTCHA ref={recaptchaRef} sitekey={captchaKey} onChange={(val) => handleCaptchaChange(val)}/>
											</div>
											<button type="submit" disabled={!captchaValue}>
												Send Message
											</button>
										</form>
										:
										<SuccessMessage>
											Thank you for your message. We will get back to you as soon as possible.
										</SuccessMessage>
									}
								</div>
							</div>
						</div>
					</div>
				</section>
			</div>
		</Styles>
	);
};

export default contactPage;

export const query = graphql`
	query {
		settings: sanitySiteSettings(_id: {eq: "ROMCsiteSettings"})  {
			description
			id
			logo {
				asset {
					fixed {
						src
					}
				}
			}
			favicon {
				asset {
					fixed(height: 48, width: 48) {
						src
					}
				}
			}
			image {
				asset {
				fixed(height: 400, width: 400) {
					base64
					srcWebp
					srcSetWebp
				}
				}
			}
			keywords
			primaryColor {
				hex
			}
			secondaryColor {
				hex
			}
			title
		}
		regions: allSanityRomCregionPage {
			nodes {
				slug {
					current
				}
				name
				showInNav
			}
		}
		themes: allSanityRomCtheme {
			nodes {
			name
			slug {
				current
			}
			showInNav
			}
		}
	}
`
